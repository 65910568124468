import "./Home.css";
import Header from "../../components/Header/Header";
import Praises from "../Praises/Praises";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SocialIcon } from "react-social-icons";
import { useEffect } from "react";

function Home() {
	// when not at top of page put background on navbar

	// document.addEventListener("DOMContentLoaded", () => {
	// 	console.log(window.location);
	// 	var hash = window.location.hash;

	// 	if (hash) {
	// 		var target = document.querySelector(hash);
	// 		if (target) {
	// 			setTimeout(function () {
	// 				target.scrollIntoView({
	// 					behavior: "smooth",
	// 				});
	// 			}, 100);
	// 		}
	// 	}
	// });

	// react ready - loaded
	// window.onload = function () {
	// 	console.log(window.location);
	// 	var hash = window.location.hash;

	// 	if (hash) {
	// 		var target = document.querySelector(hash);
	// 		if (target) {
	// 			setTimeout(function () {
	// 				target.scrollIntoView({
	// 					behavior: "smooth",
	// 				});
	// 			}, 100);
	// 		}
	// 	}
	// };

	const getQueryParam = (name) => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get(name);
	};

	const scrollToSection = () => {
		const sectionId = getQueryParam("section");
		if (sectionId) {
			const sectionElement = document.getElementById(sectionId);
			if (sectionElement) {
				sectionElement.scrollIntoView({ behavior: "smooth" });
			}
		}
	};

	const emailMe = () => {
		window.location.href = "mailto:rbrookscreatives@gmail.com";
	};

	useEffect(() => {
		scrollToSection();
	}, []);

	return (
		<div className="App">
			<Header />
			<Box className="hero" id="home">
				<div className="black-overlay" />
				<p className="hero-text">Rachel Brooks</p>
				<p className="hero-subtext"> Creatives</p>
			</Box>
			{/* section 3 */}
			<Grid
				container
				id="about"
				sx={{
					margin: "3rem 0",
					padding: "0 auto",
				}}
			>
				{/* <img src="/img/hero.jpg" alt="img" className="about-accent" /> */}
				<Grid
					item
					lg={5}
					md={5}
					sm={12}
					className="about-img-container"
					sx={{
						// sm - margin
						margin: "0",
					}}
				>
					<div className="about-img-accent"></div>
					<img src="/img/rachel1.jpg" alt="img" className="about-img" />
				</Grid>
				<Grid
					item
					lg={5}
					md={5}
					sm={12}
					className="about-text-container"
					sx={{
						// sm - margin
						margin: "0 2rem",
						// md - margin
						"@media (min-width: 600px)": {
							margin: "0 1rem",
						},
						// lg - margin
						"@media (min-width: 960px)": {
							margin: "0 1rem",
						},
					}}
				>
					<h1 className="about-text1"> Hey There! </h1>
					<h1 className="about-text2"> I'm Rachel Brooks </h1>
					{/* <h4 className="about-text-location">- Charlotte, NC -</h4> */}

					<p className="about-text3">
						{" "}
						I'm a Maryland native living in Charlotte, North Carolina. I grew up
						traveling the world and moving around quite a bit. In fact, I have
						lived in eleven different houses, six different cities, four
						different states, two different countries, and hit my dream of
						visiting all seven continents! Moving around has given me a growing
						passion for traveling, learning, and embracing new cultures.
						<br></br>
						<br></br>I am a giraffe loving, travel-obsessed girl, rooted by
						family, friends, my dogs, and my love for creating design. I don’t
						know what came first: my love of photography or my love of traveling
						but one has certainly inspired the other. There is something
						empowering about looking through my lens and being able to capture
						moments in time, not only of my life but for others as well.
						<br></br>
						<br></br>
						My love for design came from a graphic design class in high school
						that I initially disliked. After about a month and a half into the
						class and some serious patience I realized that my creations could
						influence how others see things while at the same time fulfilling my
						creative nature. From there my love grew and I have never looked
						back. Funny how life works.
					</p>
				</Grid>
			</Grid>
			<div className="praises-section" id="praises">
				<Praises />
			</div>

			{/* <div className="photography"> </div> */}
			{/* section 4 */}

			<div className="contact-me" id="contact">
				<div className="contact-me-text">
					<p className="contact-me-header">
						<span className="contact-me-header-span">CONTACT</span>
					</p>

					<Grid
						container
						sx={{
							justifyContent: "space-evenly",
						}}
					>
						<Grid
							item
							lg={4}
							md={4}
							sm={12}
							sx={{
								margin: "2rem",
								padding: "2rem",
							}}
						>
							<h1 className="contact-me-text1"> Say Hi </h1>
							<p>
								I'd love to hear from you! Feel free to reach out and I will get
								back to you very soon.
							</p>
							<div className="contact-me-social">
								<SocialIcon
									url="https://www.instagram.com/rachelbrooksphotography/"
									className="contact-me-social-icon"
									target="_blank"
									fgColor="#de9f60cc"
									bgColor="#fff"
									style={{ height: 35, width: 35 }}
								/>
								<SocialIcon
									url="https://www.linkedin.com/in/rachelbrooks18/"
									className="contact-me-social-icon"
									target="_blank"
									fgColor="#de9f60cc"
									bgColor="#fff"
									style={{ height: 35, width: 35 }}
								/>
							</div>
							<button className="contact-me-button" onClick={emailMe}>
								{" "}
								EMAIL ME{" "}
							</button>
						</Grid>
						<Grid
							item
							lg={4}
							md={4}
							sm={12}
							// spacing={5}
							className="contact-me-grid-image"
						>
							<div className="background-contact" />
							{/* img of rachel */}
							<img
								src="/img/rachel3.jpg"
								alt="img"
								className="contact-me-img"
							/>
						</Grid>
					</Grid>

					{/* email button that opens email */}

					{/* <p className="contact-me-text3">
						{" "}
						I am currently available for freelance work. If you are interested
						in working together, please fill out the form below and I will get
						back to you as soon as possible.{" "}
					</p> */}
				</div>
			</div>
		</div>
	);
}

export default Home;
