import "./Galleries.css";
import Header from "../../components/Header/Header";
// import fs
import axios from "axios";

import { Button, Fade, Grid, Modal, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { Masonry, MasonryItem } from "@mui/lab";
// useParams
import { useParams } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { useEffect, useState } from "react";

import Images from "../../components/Images/Images";

import PhotographyGallery from "../PhotographyGallery/PhotographyGallery";
import GraphicGallery from "../GraphicGallery/GraphicGallery";

export function Galleries() {
	// useParams
	let { type, category } = useParams();

	let isGrid = false;

	if (category === "photography") {
		isGrid = true;
	}

	console.log(category, type);

	if (
		[
			"emails",
			"newsletters",
			"product-launch-campaigns",
			"promo-campaigns",
			"printed-materials",
		].includes(type)
	) {
		isGrid = true;
		category = "graphics";
	}

	// lets return a different rendering based on the category and type
	return isGrid ? (
		<PhotographyGallery type={type} category={category} />
	) : (
		<GraphicGallery type={type} category={category} />
	);
}

export default Galleries;
