import "./Graphics.css";
import Header from "../../components/Header/Header";
import { Grid, Paper } from "@mui/material";
// router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import RachelLogo from "../../components/RachelLogo/RachelLogo";

export const Graphics = () => {
	// state;
	const [photographs, setPhotographs] = useState([]);

	// useeffect
	useEffect(() => {
		console.log(process.env.REACT_APP_API_URL);
		// fetch images
		axios
			.get(`${process.env.REACT_APP_API_URL}/categories/graphics`)
			.then((data) => {
				const order = [
					"logos",
					"packaging",
					"printed-materials",
					"emails",
					"miscellaneous",
					"product-launches",
					"promo-campaigns",
					"newsletters",
				];

				data.data.sort((a, b) => {
					return order.indexOf(a.name) - order.indexOf(b.name);
				});

				setPhotographs(data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className="App">
			<Header />
			<div className="gallery-section">
				{photographs.length > 0 ? (
					<div className="section-header"> Graphics </div>
				) : (
					<></>
				)}
				<Grid container spacing={10} justifyContent="center">
					{photographs.length > 0 ? (
						photographs.map((photograph, index) => (
							<Grid
								key={index}
								item
								lg={3}
								md={6}
								sm={12}
								sx={{
									// square
									height: "50vh",
									width: "100%",
									marginBottom: "2rem",
								}}
							>
								<a
									className="gallery-item"
									href={"/graphics/" + photograph.name}
								>
									<Paper
										elevation={3}
										sx={{
											// height: "100%",
											// width: "100%",
											// contain image
											backgroundSize: "cover",
											backgroundPosition: "center",
											backgroundRepeat: "no-repeat",
											// square
											height: "100%",
											width: "100%",
										}}
									>
										<img
											src={
												`${process.env.REACT_APP_API_URL}/images/graphics/` +
												photograph.name +
												"/" +
												photograph.image
											}
											alt="img"
											className="gallery-img"
										/>
										<h4>
											{photograph.name
												.split("-")
												.map(
													(word) => word.charAt(0).toUpperCase() + word.slice(1)
												)
												.join(" ")}
										</h4>
									</Paper>
								</a>
							</Grid>
						))
					) : (
						<RachelLogo />
					)}
				</Grid>
			</div>
		</div>
	);
};

export default Graphics;
