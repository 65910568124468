import "./Images.css";
import { useState } from "react";
import { Box, Fade, Modal, Paper } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Images = ({ index, category, type, image, images, style }) => {
	const [open, setOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(index);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const handlePrevious = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
	};

	return (
		<Box
			key={index}
			sx={{
				position: "relative",
				overflow: "hidden",
				display: style?.display || "block",
				width: style?.width || "100%",
				maxHeight: style?.maxHeight || "auto",
			}}
		>
			<img
				src={
					`${process.env.REACT_APP_API_URL}/images/` +
					category.toLowerCase() +
					"/" +
					type +
					"/" +
					image
				}
				alt="img"
				style={{
					width: "100%",
					height: "100%",
					objectFit: "cover",
					objectPosition: "center",
					maxHeight: style?.maxHeight || "auto",
				}}
				onClick={handleOpen}
			/>

			<Modal
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Fade in={open}>
					<Paper
						className="modal"
						sx={{
							position: "relative",
							outline: "none",
							width: "80vw", // Set a fixed width for consistency
							maxWidth: "1200px", // Optional: Limit maximum width
							height: "auto",
							padding: "1rem",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<div
							onClick={handleClose}
							style={{
								position: "absolute",
								top: "1rem",
								right: "1rem",
								cursor: "pointer",
								fontSize: "1.5rem",
								color: "white",
								zIndex: 1,
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								padding: "0.5rem 1rem",
								borderRadius: "5px",
							}}
						>
							x
						</div>

						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<LazyLoadImage
								src={
									`${process.env.REACT_APP_API_URL}/images/` +
									category.toLowerCase() +
									"/" +
									type +
									"/" +
									images[currentIndex]
								}
								alt="img"
								style={{
									width: "100%",
									maxHeight: "70vh",
									objectFit: "contain",
									border: "none",
									outline: "none",
								}}
							/>
						</div>

						<div
							onClick={handlePrevious}
							style={{
								position: "absolute",
								top: "50%",
								left: "1rem",
								transform: "translateY(-50%)",
								cursor: "pointer",
								fontSize: "2rem",
								color: "white",
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								padding: "0.5rem 1rem",
								borderRadius: "5px",
								userSelect: "none",
							}}
						>
							{"<"}
						</div>
						<div
							onClick={handleNext}
							style={{
								position: "absolute",
								top: "50%",
								right: "1rem",
								transform: "translateY(-50%)",
								cursor: "pointer",
								fontSize: "2rem",
								color: "white",
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								padding: "0.5rem 1rem",
								borderRadius: "5px",
								userSelect: "none",
							}}
						>
							{">"}
						</div>
					</Paper>
				</Fade>
			</Modal>
		</Box>
	);
};

export default Images;
