import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Praise.css";

export const Praise = ({ name, date, message }) => {
	return (
		<Paper
			className="praise"
			elevation={2}
			sx={{
				// width: "50%",
				// center
				margin: "auto",
				marginTop: "5rem",
				padding: "2rem",
				marginBottom: "5rem",
				borderRadius: "1rem",
			}}
		>
			<div className="praise-name"> {name} </div>
			<Divider
				sx={{
					margin: "2rem",
					marginX: "6rem",
					color: "grey",
					fontSize: ".75rem",
				}}
			></Divider>
			<Typography variant="body1" className="praise-text">
				{" "}
				{message}{" "}
			</Typography>
		</Paper>
	);
};

export default Praise;
