import { Masonry } from "@mui/lab";
import { Divider, Grid, Paper } from "@mui/material";
import Images from "../../components/Images/Images";
import "./GraphicGallery.css";

function GraphicSection(props) {
	// if key is even
	// images from props.section.images on the right
	// if key is odd
	// images from props.section.images on the left
	// get key of section

	return (
		<Paper
			className="graphic-section"
			sx={{
				padding: "1rem",

				margin: {
					xs: "1rem",
					sm: "1rem",
					md: "2rem",
					lg: "2rem",
				},
			}}
		>
			{/* <div className="graphic-section-header">{props.section.title}</div> */}
			{/* half page */}
			<Grid container spacing={2}>
				{/* if even or odd key switch grid*/}

				{props.index % 2 === 0 ? (
					<>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="graphic-section-images">
								{/* masonary grid */}
								{/* for each image */}
								<Masonry
									columns={{
										xs: 1,
										lg: props.section.images.length < 2 ? 1 : 2,
									}}
									spacing={4}
									sx={{
										margin: "auto",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										"& .MuiImage-root": {
											maxHeight: "300px", // Set max height for the images
										},
										"& .graphic-section-images > div": {
											maxHeight: "300px", // Optional: limit container height
										},
									}}
								>
									{props.section.images.map((image, index) => (
										<Images
											key={index}
											image={image}
											type={props.type}
											category={props.category}
											style={{}}
											index={index} // Pass the current image index
											images={props.section.images} // Pass the entire image list
										/>
									))}
								</Masonry>
							</div>
						</Grid>

						{/* half page */}
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="graphic-section-details">
								<div className="graphic-section-details-header">
									{props.section.name}
								</div>
								<Divider
									sx={{
										width: "40%",
										margin: "auto",
										marginBottom: "1rem",
									}}
								/>
								<div className="graphic-section-details-description">
									{props.section.description}
								</div>
							</div>
						</Grid>
					</>
				) : (
					<>
						{/* half page */}
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="graphic-section-details">
								<div className="graphic-section-details-header">
									{props.section.name}
								</div>
								<Divider
									sx={{
										width: "40%",
										margin: "auto",
										marginBottom: "1rem",
									}}
								/>
								<div className="graphic-section-details-description">
									{props.section.description}
								</div>
							</div>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="graphic-section-images">
								{/* masonary grid */}
								{/* for each image */}
								<Masonry
									columns={{
										xs: 1,
										lg: props.section.images.length < 2 ? 1 : 2,
									}}
									spacing={4}
									sx={{
										margin: "auto",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										"& .MuiImage-root": {
											maxHeight: "300px", // Set max height for the images
										},
										"& .graphic-section-images > div": {
											maxHeight: "300px", // Optional: limit container height
										},
									}}
								>
									{props.section.images.map((image, index) => {
										return (
											<Images
												key={index}
												image={image}
												type={props.type}
												category={"Graphics"}
												style={{
													maxHeight: "300px", // Set a max height
													objectFit: "cover", // Ensure the image scales proportionally
													width: "100%", // Optional: make sure it takes the full width of its container
												}}
												index={index} // Pass the current image index
												images={props.section.images} // Pass the entire image list
											/>
										);
									})}
								</Masonry>
							</div>
						</Grid>
					</>
				)}
			</Grid>
		</Paper>
	);
}

export default GraphicSection;
